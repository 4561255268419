.loginScreen {
  height: 100vh;
  display: flex;
}
.firstColumn {
  width: 50%;
  background: #883677;
  min-height: 800px;
}
.firstColumn div {
  background-color: white;
  width: 200px;
  height: 60px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  margin: auto;
  margin-top: 100px;
  max-width: 90%;
}
.secondColumn {
  display: flex;
  justify-content: center;
  width: 50%;

  align-items: center;
  min-height: 800px;
}
.secondColumn .logoDiv {
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
  border: black solid 1px;
  border-radius: 10px;
  width: 150px;
  display: flex;
  justify-content: center;
  padding: 10px 0px;
  font-size: 20px;
  font-weight: 600;
}
@media (max-height: 880px) {
  .secondColumn .logoDiv {
    display: none;
  }
}
@media (min-width: 900px) {
  .secondColumn .logoDiv {
    display: none;
  }
}
@media (max-width: 900px) {
  .firstColumn {
    display: none;
  }
  .secondColumn {
    width: 100%;
  }
}

@media (max-width: 500px) {
  .firstColumn {
    display: none;
  }
  .secondColumn {
    width: 100%;
  }
}
