.tabHeader {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 10px;
}
.tabsDiv {
  display: flex;

  flex-wrap: wrap;
  margin-top: 20px;
}
.tabsDiv .tab {
  background-color: #883677;
  width: fit-content;
  padding: 13px 32px;
  color: white;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  cursor: pointer;
}
.tabsDiv .tabDis {
  background-color: #adb5bd;
  width: fit-content;
  padding: 13px 32px;
  color: white;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  cursor: pointer;
}
.headerButton {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.headerButton button {
  background-color: #883677;
  width: 100%;
  height: 48px;
  min-width: 150px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;

  max-width: 200px;
}
.headerButton .link {
  width: 100%;
}
.sortDiv {
  display: flex;
  margin-top: 5px;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.sortDiv .sortDivButton .sortButton {
  width: fit-content;

  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;

  background: #adb5bd;
  border: none;
  font-size: 16px;
  cursor: pointer;

  min-width: 100px;
}
.sortDiv .sortDivButton .sortButtonActive {
  width: fit-content;

  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  outline: none;
  background: #883677;
  border: none;
  font-size: 16px;
  cursor: pointer;
  cursor: pointer;
  min-width: 100px;
  cursor: default;
}
.sortDiv .sortList {
  width: fit-content;
  position: absolute;
  z-index: 3;
  background: white;
  color: black;
  padding: 5px 10px 5px 10px;
  transform: translateY(10px);
  border-radius: 4px;
  box-shadow: 0px 2px 10px grey;
  font-weight: 500;
  border: #dedede solid 1px;
  min-width: 183px;
}
.sortDiv .sortList .div {
  background-color: white;
  width: 100%;
  padding: 8px 0px;

  cursor: pointer;
  color: black;
  border-bottom: #dedede solid 1px;
}
.sortDivButtonDelete .sortButtonDelete {
  width: fit-content;

  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 20px;
  color: white;
  background-color: #fc3934;
  cursor: pointer;
  margin-left: 40px;
  margin-top: 5px;
}
.sortDiv .sortDivButton {
  height: fit-content;

  margin-top: 5px;
  margin-bottom: 5px;
}

.sortDiv .sortList .div:hover {
  color: #883677;
}
@media (max-width: 544px) {
  .sortDivButtonDelete .sortButtonDelete {
    margin-left: 0px;
  }
}
@media (max-width: 370px) {
  .sortDivButtonDelete {
    width: 100%;
  }
}

.label {
  color: #6c757d;
}
.info {
  font-weight: 600;
}
.table {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
}
.tableEl {
  flex: 2;
  min-width: 45%;
  max-width: 45%;
  margin: 10px 10px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
  background: #f8f9fa;

  border: 1px solid #e9ecef;

  border-radius: 10px;
  padding: 10px;
}
.tableEl div {
  flex: 2;
  min-width: 45%;
  margin-top: 10px;
}
.buttonDiv {
  align-items: center;
}
.confirmButtonDiv {
  display: flex;
  gap: 10px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
}
.confirmButtonDiv .confirmButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  background-color: #883677;
  border-radius: 4px;
}
.confirmButtonDiv .disagreeButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  background-color: #adb5bd;
}
.button {
  background: #fc3934;
  border-radius: 4px;
  border: none;
  height: fit-content;
  padding: 4px 16px;
  color: white;
  font-size: 16px;
  cursor: pointer;
  z-index: 3 !important;
}
.disabledButton {
  /* border: none; */
  background-color: transparent;
  font-weight: 600;
  color: black;
  cursor: default;
  font-size: 16px;
  height: 30px;
  text-align: center;
}
@media (max-width: 1059px) {
  .headerButton {
    justify-content: flex-start;
  }
}
@media (max-width: 522px) {
  .headerButton {
    min-width: 100%;
  }
  .headerButton button {
    min-width: 100%;
    width: 100%;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  .tabsDiv {
    width: 100%;
  }
  .tab {
    min-width: 100%;
  }
  .modal {
    padding: 20px !important;
  }
}

@media (max-width: 522px) {
  .tabsDiv .tab {
    width: 100%;
  }
  .tabsDiv .tabDis {
    width: 100%;
    margin-right: 0px;
  }
  .tableEl {
    flex: 1;
    min-width: 100%;
    max-width: none;
  }
  .buttonDiv {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    text-align: left;
  }
  .tableEl div {
    min-width: 120px;
    margin-right: 20px;
  }
}

@media (min-width: 900px) {
  .tableEl {
    min-width: 100%;
    flex: 1;
    flex-wrap: nowrap;
  }
  .tableEl div {
    max-width: fit-content;
    margin-right: 10px;
    margin-top: 0px;

    min-width: 80px;
    max-width: 120px;
  }
  .tableEl .buttonDiv {
    min-width: none;
    max-width: none;
    flex: 1;
    display: flex;

    justify-content: flex-end;
  }
  .buttonDiv {
    text-align: center;
  }
  .miniInfo {
    transform: translate(0px) !important;
  }
}
.modal {
  top: 50%;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 90%;

  max-width: 500px !important;

  padding: 40px;
  border-radius: 20px;
  max-width: 600px;
  z-index: 2;
}
.backdrop {
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  min-width: 100vw !important;
  max-width: 100vw !important;
  width: 100vw !important;
  z-index: 2;
  background: rgba(0, 0, 0, 0.654);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.select {
  width: 100%;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  padding: 10px;
  color: #6c757d;
  outline: none;
  background: #f8f9fa;
  height: 55px;
  font-size: 16px;
  margin-top: 10px;
  -webkit-appearance: none;
}
.errorBox {
  background-color: #fe8c23;
  font-size: 10px;
  text-align: center;
  padding: 3px;
  min-width: 20px !important;
}
.paidBox {
  background-color: #46d35d;
  font-size: 10px;
  text-align: center;
  padding: 3px;
  min-width: 20px !important;
}
.swimmerInfo {
  min-width: 160px !important;
}
.miniInfo {
  transform: translate(-5px, -21px);
}
