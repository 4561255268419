.container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: white;
}
.box {
  width: 100%;
  text-align: center;
  text-align: center;
  /* border: 1px solid black; */
  max-width: 550px;
}
.logo {
  width: fit-content;
  padding: 5px 20px;
  font-size: 20px;
  font-weight: 700;
  background-color: #883677;
  color: white;
  border-radius: 8px;
  margin: 0px auto 20px auto;
}
.heading {
  line-height: 35px;
  margin-bottom: 20px;
}
.descript {
  margin-bottom: 20px;
  width: 70%;
  margin: 0px auto;
  margin-bottom: 20px;
}
.descript span {
  color: #883677;
  font-weight: 700;
}
.button {
  width: 100%;
  background-color: #883677;
  border: none;
  color: white;
  padding: 15px 0px;
  font-size: 16px;
  font-weight: 700;
  border-radius: 4px;
}
