.resetPassword {
  width: 65%;
  max-width: 500px;
}
.resetPasswordForm {
  margin-top: 10px;
  position: relative;
}
.resetPasswordForm input {
  margin: 16px 0px;
  height: 55px;
  border-radius: 4px;
  border: 1px solid #adb5bd;
  background: #f8f9fa;
  font-size: 16px;
  padding-left: 15px;
  width: 100%;
  font-weight: 600;
  display: block;
}
.optionsDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.optionsDiv p {
  color: #883677;
  text-decoration: underline;
  font-size: 16px;
}
@media (max-width: 600px) {
  .resetPassword {
    width: 80%;
  }
}
@media (max-width: 400px) {
  .resetPassword {
    width: 90%;
  }
}
