.swimmersHeader {
  display: Flex;
  align-items: center;
  flex-wrap: wrap;
}
.headerButton {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.headerButton button {
  background-color: #883677;
  width: fit-content;
  padding: 13px 32px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  min-width: 240px;
}
.label {
  color: #6c757d;
}
.info {
  font-weight: 600;
}
.table {
  display: flex;
  flex-wrap: wrap;

  margin-top: 15px;
}
.tableEl {
  flex: 2;
  min-width: 45%;
  max-width: 45%;

  display: flex;
  flex-wrap: wrap;
  margin: 0px 10px 10px 0px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;

  border-radius: 10px;
  padding: 10px;
}
.tableElDiv {
  flex: 2;
  min-width: 130px;
}
.infoIcon {
  position: absolute;

  right: 10px;
  top: 10px;
  cursor: pointer;
}
.confirmButtonDiv {
  display: flex;
  gap: 10px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  margin-top: 0px;
}
.confirmButtonDiv .confirmButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  background-color: #883677;
  border-radius: 4px;
}
.confirmButtonDiv .disagreeButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  background-color: #adb5bd;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50%;
  padding: 40px;
  border-radius: 20px;
  max-width: 600px;
  z-index: 4;
}
.addSwimmerForm {
  margin-top: 10px;
}
.addSwimmerForm input {
  width: 100%;
  display: block;
  margin-bottom: 13px;
  height: 55px;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background-color: #f8f9fa;
  padding: 0px 10px;
  font-size: 16px;
}
.popup {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
}

.addSwimmerForm select {
  width: 100%;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  padding: 10px;
  color: #6c757d;
  background: #f8f9fa;
  -webkit-appearance: none;
  font-size: 15px;
}
.modal button {
  margin-top: 20px;
  width: 100%;
  height: 55px;
  color: white;
  border: none;
  background-color: #883677;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.addSwimmerForm button {
  margin-top: 20px;
  width: 100%;
  height: 55px;
  color: white;
  border: none;
  background-color: #883677;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

@media (max-width: 400px) {
  .swimmersHeader {
    display: block;
  }
  .headerButton button {
    width: 100%;
    margin-top: 10px;
    min-width: 100%;
  }
}
@media (max-width: 700px) {
  .modal {
    width: 80%;
  }
}
@media (max-width: 700px) {
  .modal {
    width: 90%;
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .tableEl {
    flex: 1;
    min-width: 100%;
    max-width: none;
  }

  .tableElDiv {
    min-width: 150px;
  }
  .popup {
    min-width: 100%;
  }
}

@media (min-width: 900px) {
  .tableEl {
    min-width: 100%;
    flex: 1;
    flex-wrap: nowrap;
    position: relative;
  }
  .tableElDiv {
    max-width: fit-content;
    margin-right: 20px;

    min-width: 130px;
  }
}
