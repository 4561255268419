.registration {
  width: 65%;
  max-width: 500px;
  min-height: 800px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}
.registration .registrationDiv {
  height: fit-content;
  width: 100%;
  padding: 0px 0px 50px 0px;
}
.registrationForm {
  margin-top: 10px;
  position: relative;
}
.registrationForm input {
  margin: 16px 0px;
  height: 55px;
  border-radius: 4px;
  border: 1px solid #adb5bd;
  background: #f8f9fa;
  font-size: 16px;
  padding-left: 15px;
  width: 100%;
  font-weight: 600;
  display: block;
}

.optionsDiv {
  display: flex;
  justify-content: space-between;
  margin-top: 18px;
}
.optionsDiv p {
  color: #883677;
  text-decoration: underline;
  font-size: 16px;
}
@media (max-width: 600px) {
  .registration {
    width: 80%;
  }
}
@media (max-width: 400px) {
  .registration {
    width: 90%;
  }
}
.checkboxDiv {
  display: flex;
  margin-bottom: -5px;
  align-items: center;
}
.checkboxDiv div {
  margin-left: 10px;
}
