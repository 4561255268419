* {
  padding: 0;
  margin: 0;
  font-family: "Open Sans", sans-serif;
  box-sizing: border-box;
}

.h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
.h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}
.h3 {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  margin: 0;
}
.backdrop {
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.654);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.contentContainer {
  padding: 50px 20px;
  max-width: 1400px;
  margin: auto;
}
.closeModal {
  position: absolute;
  top: 20px;
  right: 20px;
}

.errorInput {
  color: red;
  font-size: 13px;
  margin-top: -10px;
  margin-left: 10px;
}
.hiddenMenu {
  position: absolute;

  right: 0px;
  background-color: #883677;
  padding: 20px 0px;
  z-index: 2;

  width: 100%;
  top: -800%;
  z-index: 2;
  box-shadow: 0px 0px 40px black;
}
@media (min-width: 900px) {
  .hiddenMenu {
    display: none;
  }
}
.open-enter {
  top: -800%;
}
.open-enter-active {
  top: 0px;
  transition: 400ms ease-out;
}
.open-enter-done {
  top: 0px;
}
.open-exit {
  top: 0px;
}
.open-exit-active {
  top: -800%;
  transition: 400ms ease-out;
}
.open-exit-done {
  top: -800%;
}

.checbox {
  border: red solid 2px;
  cursor: pointer;
  height: 45px;
}
.checkbox {
  -webkit-appearance: none;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  border: 1px solid black;
  border-radius: 5px;
  outline: none;
  position: relative;
  cursor: pointer;
  margin-bottom: -2px;
  margin-left: -28px;
}

.checkbox:checked,
.checkbox:active {
  border: 1px solid #adb5bd;
}
.checkbox:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  transform: translate(-50%, -50%);
  border-radius: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: background 0.3s;
}
.checkbox:checked:before {
  background: purple;
}
