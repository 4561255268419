.tabsDiv {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
.tabsDiv .tab {
  background-color: #883677;
  width: fit-content;
  padding: 13px 32px;
  color: white;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  cursor: pointer;
}
.tabsDiv .tabDis {
  background-color: #adb5bd;
  width: fit-content;
  padding: 13px 32px;
  color: white;
  margin-right: 18px;
  margin-bottom: 18px;
  border-radius: 4px;
  cursor: pointer;
}
.sortDiv {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.label {
  color: #6c757d;
}
.info {
  font-weight: 600;
}
.table {
  display: flex;
  flex-wrap: wrap;
  /* gap: 10px; */
}
.tableEl {
  flex: 2;
  min-width: 48%;
  max-width: 48%;
  margin: 10px 10px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;

  border-radius: 10px;
  padding: 10px;
}
.tableEl div {
  flex: 1;
}
.button {
  background: #46d35d;
  border-radius: 4px;
  color: white;
  padding: 4px 16px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: fit-content;
}
.logoutButton {
  background: #fc3934;
  border-radius: 4px;
  color: white;
  padding: 4px 16px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  height: fit-content;
}
.disabledButton {
  border: none;
  background-color: transparent;
  font-weight: 600;
  color: black;
  cursor: default;
  font-size: 16px;

  height: fit-content;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50%;
  padding: 40px;
  border-radius: 20px;

  max-width: 600px;
  z-index: 3;
}

.modal form fieldset {
  border: none;
}
.modal form fieldset div {
  display: block;
  margin: 5px 0px;
}
.modal form fieldset div input {
  margin-right: 10px;
}
.radio {
  -webkit-appearance: none;
  width: 20px;
  max-width: 20px;
  height: 20px;
  max-height: 20px;
  border: 1px solid #adb5bd;
  border-radius: 10px;
  outline: none;
  position: relative;
  cursor: pointer;
  margin-bottom: -2px;
}
.radio:checked,
.radio:active {
  border: 1px solid #adb5bd;
}
.radio:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  transform: translate(-50%, -50%);
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  transition: background 0.3s;
}
.radio:checked:before {
  background: purple;
}

.sortDiv .sortDivButton {
  height: fit-content;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 10px;
}
.sortDivButtonDelete {
  height: fit-content;
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 5px;
}
.sortDiv .sortDivButton .sortButton {
  width: fit-content;

  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  outline: none;
  background: #adb5bd;
  border: none;
  font-size: 16px;
  cursor: pointer;
  cursor: pointer;
  min-width: 100px;
}
.sortDiv .sortDivButton .sortButtonActive {
  width: fit-content;

  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 10px;
  color: white;
  outline: none;
  background: #883677;
  border: none;
  font-size: 16px;
  cursor: pointer;
  cursor: pointer;
  min-width: 100px;
  cursor: default;
}
.sortDivButtonDelete .sortButtonDelete {
  width: fit-content;

  margin-right: 10px;
  border-radius: 4px;
  padding: 5px 20px;
  color: white;
  background-color: #fc3934;
  cursor: pointer;
  margin-left: 40px;
}
@media (max-width: 545px) {
  .sortDivButtonDelete .sortButtonDelete {
    margin-left: 0px;
  }
}
@media (max-width: 370px) {
  .sortDivButtonDelete {
    width: 100%;
  }
}
.sortDiv .sortList {
  width: fit-content;
  position: absolute;
  z-index: 3;
  background: white;
  color: black;
  padding: 5px 10px 5px 10px;
  transform: translateY(10px);
  border-radius: 4px;
  box-shadow: 0px 2px 10px grey;
  font-weight: 500;
  border: #dedede solid 1px;
  min-width: 183px;
}
.sortDiv .sortList .div {
  background-color: white;
  width: 100%;
  padding: 8px 0px;

  cursor: pointer;
  color: black;

  border-bottom: #dedede solid 1px;
}

.sortDiv .sortList .div:hover {
  color: #883677;
}
.select {
  width: 100%;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  padding: 10px;
  color: #6c757d;
  outline: none;
  background: #f8f9fa;
  height: 55px;
  font-size: 16px;
  margin-top: 10px;
  -webkit-appearance: none;
}
.buttonForm {
  margin-top: 20px;
  width: 100%;
  height: 55px;
  color: white;
  border: none;
  background-color: #883677;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.backdrop {
  background: rgba(0, 0, 0, 0.654);
  backdrop-filter: blur(3px);

  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
}
.confirmButtonDiv {
  display: flex;
  gap: 10px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  margin-top: 10px;
}
.filterDiv {
  background-color: #e9ecef;
  width: 100%;
  gap: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
  border-radius: 4px;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
}
.filterEl {
  z-index: 3;

  padding: 5px;
  border-radius: 4px;
  background-color: #adb5bd;
  margin-bottom: 5px;
  color: white;
  cursor: pointer;
  min-width: 30%;
  max-width: 30%;
}
.filterElActive {
  z-index: 3;

  padding: 5px;
  border-radius: 4px;
  background-color: #883677;
  margin-bottom: 5px;
  color: white;
  cursor: pointer;
  min-width: 30%;
}
@media (min-width: 900px) {
  .filterEl {
    min-width: 10%;
  }
  .filterElActive {
    min-width: 10%;
  }
}
.confirmButtonDiv .confirmButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  background-color: #883677;
  border-radius: 4px;
}
.confirmButtonDiv .disagreeButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  background-color: #adb5bd;
}
.backdropButton {
  background: rgba(0, 0, 0, 0.654);
  backdrop-filter: blur(3px);
  height: 100%;
  width: 100% !important;
  min-width: 100% !important;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 2;
}
@media (max-width: 700px) {
  .modal {
    width: 80%;
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .tabsDiv .tab {
    width: 100%;
    margin-right: 0px;
  }
  .tabsDiv .tab {
    min-width: 100%;
    margin-right: 0px;
  }
  .tabsDiv .tabDis {
    width: 100%;
    margin-right: 0px;
  }
  .modal {
    width: 90%;
  }
  .tableEl {
    flex: 1;
    min-width: 100%;
    max-width: none;
  }
  .buttonDiv {
    display: flex;
    justify-content: center;
    margin: 10px 0px;
    align-items: center;
  }
  .tableEl div {
    min-width: 100px;
  }
}

@media (min-width: 1050px) {
  .tableEl {
    min-width: 100%;
    flex: 1;
    flex-wrap: nowrap;
    position: relative;
  }
  .tableEl div {
    /* min-width: 100px;
    max-width: 180px; */
  }
  .tableEl .buttonDiv {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    border: none;
  }
  .modal {
    width: 600px !important;
    min-width: 600px !important;
    max-width: 600px !important;
  }
}
.expand {
  position: absolute;
  top: -5px;
  right: -5px;
}
.expand button {
  border-radius: 20px;
  width: 25px;
  border: 1px solid #adb5bd;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.epxand button p {
  transform: translateY(-15px);
}
