.NotificationGreen {
  width: 300px;
  background: #46d35d;
  padding: 15px 16px;
  border-radius: 4px;
  box-shadow: grey 0px 0px 10px;
}
.NotificationOrange {
  width: 300px;
  background: #fe8c23;
  padding: 15px 16px;
  border-radius: 4px;
  box-shadow: grey 0px 0px 10px;
}
@media (max-width: 500px) {
  .NotificationGreen,
  .NotificationOrange {
    width: 180px;
  }
}
.loadingDiv {
  padding: 20px;
}
.loading {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #883677;

  border-radius: 20px;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: auto;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
