.headerDiv {
  background-color: #883677;
  z-index: 3;
  position: relative;
  min-height: 65px;
  display: flex;
}
.header {
  display: flex;
  padding: 8px 20px;
  align-items: center;
  max-width: 1400px;
  width: 100%;
  margin: auto;
  background-color: #883677;
  z-index: 2;
}
.logoDiv {
  flex: 1;
}
.logo {
  background-color: white;
  border-radius: 4px;
  width: 100px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
}

.headerNavLink {
  margin: auto;
  margin-right: 40px;

  text-transform: uppercase;
  color: white;
  letter-spacing: 2px;
  font-size: 16px;
  text-decoration: none;
  width: fit-content;
  max-width: fit-content;
}
.modal {
  position: absolute;
  background-color: white;
  width: 50%;
  padding: 40px;
  border-radius: 20px;
  max-width: 600px;
  left: 50%;
  top: 50px;
  transform: translateX(-50%);

  z-index: 3;
}
.creditDiv {
  text-align: end;
  margin-right: 20px;
  color: white;
  font-weight: 400;
}

.formDiv input {
  width: 100%;
  display: block;
  margin-bottom: 13px;
  height: 55px;
  border: 1px solid #adb5bd;
  border-radius: 4px;
  background-color: #f8f9fa;
  padding: 0px 10px;
  font-size: 16px;
  outline: none;
}
.formDiv button {
  margin-top: 10px;
  width: 100%;
  height: 55px;
  color: white;
  border: none;
  background-color: #883677;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}
.backdrop {
  background: rgba(0, 0, 0, 0.654);
  backdrop-filter: blur(3px);
  position: fixed;
  height: 100vh;
  z-index: 3;
  top: 0px;
  width: 100%;
}
.activeNormal {
  background-color: transparent;
  text-decoration: underline;
  text-underline-offset: 8px;
}
.active {
  background-color: white;
  color: black;
}

.hiddenMenu .hiddenMenuLinks {
  display: block;
}

.hiddenMenuLinks .headerNavLink {
  display: block;
  margin: 10px auto;
  text-align: center;
  padding: 5px 20px;
  border-radius: 4px;
  width: fit-content;
}
.header.NavLink:hover {
  color: white;
}
.hamburgerDiv {
  cursor: pointer;
  display: none;
  z-index: 3 !important;
}
.hamburger1,
.hamburger2,
.hamburger3 {
  content: "";
  width: 30px;
  height: 4px;
  border-radius: 2px;
  background-color: white;
  margin-bottom: 4px;
}

.hamburger:before {
  content: "";
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: white;

  margin-top: 50px;
}
.logoutIcon {
  height: 26px;
  margin-right: -20px;
}
.logoutDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: auto;
  border-top: white 1px solid;
  padding-top: 10px;
  cursor: pointer;
}
.logoutDiv p {
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.logoutIconMenu {
  height: 26px;
  margin-left: 20px;
}
.modal {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50%;
  padding: 40px;
  border-radius: 20px;

  max-width: 600px;
  z-index: 3;
}

.modal .confirmButtonDiv {
  display: flex;
  gap: 10px;
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  margin-top: 20px;
}
.confirmButtonDiv .confirmButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  background-color: #883677;
  border-radius: 4px;
}
.confirmButton .button {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 100%;
  color: white;
  font-size: 16px;
  background-color: #883677;
  border-radius: 4px;
}
.confirmButtonDiv .disagreeButton {
  border: none;
  cursor: pointer;
  height: 55px;
  width: 50%;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  background-color: #adb5bd;
}
.hiddenMenuCreditDiv {
  border-top: white solid 1px;
  text-align: center;
  width: fit-content;
  padding: 8px 20px;
  margin: auto;
  color: white;
}
@media (max-width: 700px) {
  .modal {
    width: 80%;
    padding: 20px;
  }
}
@media (max-width: 500px) {
  .modal {
    width: 90%;
  }
}
@media (max-width: 900px) {
  .links {
    display: none;
  }
  .creditDiv {
    display: none;
  }
  .hamburgerDiv {
    display: block;
  }
  .logoutIcon {
    display: none;
  }
  .modal {
    padding: 20px;
  }
}
