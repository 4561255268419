.popup div {
  text-align: center;
  padding: 6px 0px;
  font-size: 15px;
}
.popup div:hover {
  background: rgba(0, 0, 0, 0.04);
}
.popup {
  position: absolute;
  top: -30px;
  left: -85px;
  background-color: white;
  width: 80px;
  padding: 10px 0px;
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  z-index: 3 !important;
}
.backdrop {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50%;
  padding: 40px;
  border-radius: 20px;
  max-width: 600px;
  z-index: 4;
}

