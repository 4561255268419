.listItem {
  display: flex;
  border: 1px solid #e9ecef;
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 6px;
  background: #f8f9fa;
  align-items: center;
  min-width: fit-content;
}
.listItemText {
  display: flex;
  flex: 1;
}
.listItemText div {
  width: 120px;
}
.title {
  color: #6c757d;
}
.info {
  font-weight: 600;
  margin-top: 3px;
}

.newsList {
  margin-bottom: 16px;

  border-radius: 10px;
  padding: 10px;
  background: #f8f9fa;

  border: 1px solid #e9ecef;
}
.boardGrid {
  display: flex;
}
.list {
  margin-top: 10px;
  display: flex;
  box-sizing: border-box;
  flex-wrap: wrap;
  gap: 10px;
}
.listItem {
  flex: 1;
  flex-wrap: wrap;

  display: flex;
  gap: 10px;
  border: 1px solid #e9ecef;
  border-radius: 10px;
  position: relative;
  width: 100%;
  max-width: 100%;
  min-width: 100%;
}
.listItem div {
  flex: 2;
}
.listItem div .label {
  color: #6c757d;
}
.listItem div .text {
  font-weight: 600;
  margin-top: 5px;
}
.dataGrid {
  margin-top: 10px;
}
.infoIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;

  cursor: pointer;
}
.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  width: 50%;
  padding: 40px;
  border-radius: 20px;
  max-width: 600px;
  z-index: 3;
}
.modal .button {
  width: 100%;
  height: 55px;
  background: #883677;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
  margin-top: 20px;
  cursor: pointer;
}
@media (max-width: 600px) {
  .dataGrid {
    width: 100%;
  }
  .list {
    display: block;
  }
  .listItem {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }
  .listItem div {
    width: 40%;
    min-width: 40%;
  }
  .modal {
    width: 90%;
    padding: 20px;
  }
}
